.react-international-phone-country-selector {
  position: relative;
}
.react-international-phone-country-selector-button {
  display: flex;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid var(--react-international-phone-country-selector-border-color, var(--react-international-phone-border-color, gainsboro));
  margin: 0;
  appearance: button;
  /* stylelint-disable-next-line */
  -webkit-appearance: button;
  background-color: var(--react-international-phone-country-selector-background-color, var(--react-international-phone-background-color, white));
  cursor: pointer;
  text-transform: none;
  user-select: none;
}
.react-international-phone-country-selector-button:hover {
  background-color: var(--react-international-phone-country-selector-background-color-hover, whitesmoke);
}
.react-international-phone-country-selector-button--hide-dropdown {
  cursor: auto;
}
.react-international-phone-country-selector-button--hide-dropdown:hover {
  background-color: transparent;
}
.react-international-phone-country-selector-button__button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-international-phone-country-selector-button__flag-emoji {
  margin: 0 4px;
}
.react-international-phone-country-selector-button__flag-emoji--disabled {
  opacity: 0.75;
}
.react-international-phone-country-selector-button__dropdown-arrow {
  border-top: var(--react-international-phone-country-selector-arrow-size, 4px) solid var(--react-international-phone-country-selector-arrow-color, #777);
  border-right: var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;
  border-left: var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;
  margin-right: 4px;
  transition: all 100ms ease-out;
}
.react-international-phone-country-selector-button__dropdown-arrow--active {
  transform: rotateX(180deg);
}
.react-international-phone-country-selector-button__dropdown-arrow--disabled {
  border-top-color: var(--react-international-phone-disabled-country-selector-arrow-color, #999);
}
.react-international-phone-country-selector-button--disabled {
  background-color: var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));
  cursor: auto;
}
.react-international-phone-country-selector-button--disabled:hover {
  background-color: var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));
}
