.react-international-phone-dial-code-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-dial-code-preview-border-color, var(--react-international-phone-border-color, gainsboro));
  margin-right: -1px;
  /* for border overlap */
  background-color: var(--react-international-phone-dial-code-preview-background-color, var(--react-international-phone-background-color, white));
  color: var(--react-international-phone-dial-code-preview-text-color, var(--react-international-phone-text-color, #222));
  font-size: var(--react-international-phone-dial-code-preview-font-size, var(--react-international-phone-font-size, 13px));
}
.react-international-phone-dial-code-preview--disabled {
  background-color: var(--react-international-phone-dial-code-preview-disabled-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));
  color: var(--react-international-phone-dial-code-preview-disabled-text-color, var(--react-international-phone-disabled-text-color, #666));
}
