.react-international-phone-input-container {
  display: flex;
}
.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin-right: -1px;
  /* for border overlap */
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: var(--react-international-phone-text-color, #222);
  font-family: inherit;
  font-size: var(--react-international-phone-font-size, 13px);
}
.react-international-phone-input-container .react-international-phone-input:focus {
  outline: none;
}
.react-international-phone-input-container .react-international-phone-input--disabled {
  background-color: var(--react-international-phone-disabled-background-color, whitesmoke);
  color: var(--react-international-phone-disabled-text-color, #666);
}
