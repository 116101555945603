.react-international-phone-country-selector-dropdown {
  position: absolute;
  z-index: 1;
  top: var(--react-international-phone-dropdown-left, 44px);
  left: var(--react-international-phone-dropdown-left, 0);
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  background-color: var(--react-international-phone-dropdown-item-background-color, var(--react-international-phone-background-color, white));
  box-shadow: var(--react-international-phone-dropdown-shadow, 2px 2px 16px rgba(0, 0, 0, 0.25));
  color: var(--react-international-phone-dropdown-item-text-color, var(--react-international-phone-text-color, #222));
}
.react-international-phone-country-selector-dropdown__list {
  display: flex;
  width: 300px;
  max-height: 200px;
  flex-direction: column;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
}
.react-international-phone-country-selector-dropdown__search-input {
  width: 100%;
  min-height: var(--react-international-phone-dropdown-item-height, 28px);
  padding: 2px 8px;
}
.react-international-phone-country-selector-dropdown__clear-search-input {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 21px;
  height: 21px;
}
.react-international-phone-country-selector-dropdown__list-item {
  display: flex;
  min-height: var(--react-international-phone-dropdown-item-height, 28px);
  box-sizing: border-box;
  align-items: center;
  padding: 2px 8px;
}
.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
  margin-right: 8px;
}
.react-international-phone-country-selector-dropdown__list-item-country-name {
  margin-right: 8px;
  font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
}
.react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: var(--react-international-phone-dropdown-item-dial-code-color, gray);
  font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
}
.react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke));
  cursor: pointer;
}
.react-international-phone-country-selector-dropdown__list-item--selected, .react-international-phone-country-selector-dropdown__list-item--focused {
  background-color: var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke);
  color: var(--react-international-phone-selected-dropdown-item-text-color, var(--react-international-phone-text-color, #222));
}
.react-international-phone-country-selector-dropdown__list-item--selected .react-international-phone-country-selector-dropdown__list-item-dial-code, .react-international-phone-country-selector-dropdown__list-item--focused .react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: var(--react-international-phone-selected-dropdown-item-dial-code-color, var(--react-international-phone-dropdown-item-dial-code-color, gray));
}
.react-international-phone-country-selector-dropdown__list-item--focused {
  background-color: var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke));
}
